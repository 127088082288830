.work {
  position: relative;

  padding: 0 0 16px;

  overflow-y: scroll;

  flex-grow: 1;

  &__hdr {
    display: block;
    width: 100%;
    padding: 12px 0 16px;
    margin: 0;
    
    text-align: center;

    color: $white;
    background: $light-blue;

    font-style: italic;
    font-family: $mono;
    text-shadow: 2px 3px 6px #024484;
  }

	&__cntnt {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;

  	margin: 0 auto;

    p {
    	margin: 16px 0;
    }
  }

  &__app {
    position: relative;

    width: calc(33.333% - 16px);
    aspect-ratio: 1 / 1;
    max-height: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 9px -2px rgba(0,0,0,0);

    margin: 8px;

    background: #001eff;

    transition: box-shadow 0.5s ease;

    &__img {
      object-fit: cover;
      object-position: 70% 40%;
      width: 100%;
      height: 100%;

      transform: scale(2);
      mix-blend-mode: screen;

      transition: transform 0.3s ease;
      pointer-events: none;
    }

    &:hover,
    &:focus-visible {
      box-shadow: 0px 0px 9px -2px rgba(34, 16, 16, 0.3);

      .work__app__img {
        transform: scale(2.3);
      }
    }

    &-wrapper {
      margin: 8px;
    }
  }
}
