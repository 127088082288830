body {
  margin: 0;
  padding: 0;
  background-image: url('./../images/starry_sky.jpg');
  background-color: rgba(0, 155, 255, 0.3);

  * {
    box-sizing: border-box;
  }
}

#app {
  color: $black;
  font-size: 16px;
  font-family: $mono;
  line-height: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 780px) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans;
  font-weight: 700;
  color: $blue;
}

p, span {
  font-family: $mono;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0;
}

button {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: $white;
  }

  &:focus-visible {
    @include focus-visible();
  }
}

img,
figure {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto;
  }

p > a,
span > a,
a.inline-link,
button.inline-link {
  position: relative;
  color: $blue;
  font-family: $mono;
  font-size: 16px;
  line-height: 1.2em;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;

  @media (max-width: 780px) {
    font-size: 14px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    right: -3px;
    bottom: 2px;
    left: -3px;
    background-color: transparent;
    z-index: 0;
    transition: color 0.3s ease, background-color 0.3s ease;
  }

  &:focus-visible {
    @include focus-visible();
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:hover {
    color: $white;

    &:after {
      background-color: $blue;
    }
  }

  &--light {
    color: $light-blue;
  }
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
