.home {
  .message {
    display: block;
    position: absolute;
    bottom: 0px;
    width: auto;
    margin: 10px 10px 0;
    font-size: 16px;
    font-family: $mono;
    opacity: 0;
    transform: translateY(100px);
    animation: showText 0.2s ease forwards 0.5s;

    @media (max-width: $md) {
      font-size: 14px;
    }

    p {
      display: inline-block;
      max-width: 72%;
      color: $black;
      background: $light-blue;
      padding: 5px 10px;
      margin: 0;
      border-radius: 10px;
    }

    a {
      color: $blue;
      background-color: transparent;
      text-decoration: underline;

      &:hover {
        color: $light-blue;
        background-color: $blue;
      }
    }

    &--right {
      position: absolute;
      right: 0;
      text-align: right;

      p {
        color: $light-blue;
        background: $blue;
      }

      a {
        color: $light-blue;
        background-color: transparent;
        text-decoration: underline;

        &:hover {
          color: $blue;
          background-color: $light-blue;
        }
      }
    }

    &:nth-of-type(1) {
      animation-delay: 0.2s
    }

    &:nth-of-type(2) {
      animation-delay: 1s
    }

    &:nth-of-type(3) {
      animation-delay: 3s
    }

    &:nth-of-type(4) {
      animation-delay: 5s
    }

    &:nth-of-type(5) {
      animation-delay: 7s
    }

    &:nth-of-type(6) {
      animation-delay: 9s
    }

    &:nth-of-type(7) {
      animation-delay: 9s
    }

    &:nth-of-type(8) {
      animation-delay: 9s
    }

    &:last-of-type {
      margin-bottom: 10px;
    }
  }
}

@keyframes showText {
  from {
    position: absolute;
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    position: relative;
    opacity: 1;
    transform: translateY(-10px);
  }
}
