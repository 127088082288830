// Variables


// colors
$white: #ffffff;
$black: #000b4c;
$blue: dodgerblue;
$light-blue: aliceblue;
$gray: #cbcbcb;


// font
$sans: 'Open Sans', sans-serif;
$mono: 'IBM Plex Mono', monospace;


// media breakpoints
$sm: 500px;
$md: 768px;
$lg: 900px;


// animations
$fadeIn: fadeIn 0.4s ease-in-out;

@keyframes fadeIn {
	from {
	  opacity: 0;
	  transform: translateY(20px);
	}

	to {
	  opacity: 1;
	  transform: translateY(0);
	}
}

$float: float 5s cubic-bezier(0.42, 0, 0.31, 0.99) infinite;

@keyframes float {
	0% {
	  transform: translateY(0);
	}

	50% {
	  transform: translateY(4px);
	}

	100% {
		transform: translateY(0);
	}
}


// focus
@mixin focus-visible() {
	outline: 1px dotted $gray;
    box-shadow: 1px 1px 4px 1px rgba($gray, 0.3);
}