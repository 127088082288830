.about {
	position: relative;

  padding: 24px 16px;

  overflow: scroll;

  animation: $fadeIn;

  flex-grow: 1;

	&__cntnt {
    > *:first-child {
      margin-top: 0;
    }

    h2 {
      display: inline-block;
      color: $blue;
      font-family: $mono;
      font-style: italic;
      text-align: center;

      @media (max-width: $sm) {
        display: block;
      }
    }

    p {
    	margin: 16px 0;
    }
	}

  &__cv {
    display: block;
    text-align: center;

    a.cv {
      display: inline-block;
      vertical-align: text-top;
    }
  }

  &__contact {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    &__item {
      position: relative;
      margin: 6px 0;
    }

    &__email {
      position: relative;
    }

    .copied {
      position: absolute;
      right: -15%;
      top: -50%;

      font-size: 11px;

      padding: 0 .5em;

      color: $white;
      background-color: hotpink;

      transform: rotate(15deg) scale(0.95);

      z-index: 1;

      visibility: hidden;
      opacity: 0;

      transition: transform .35s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;

      &.show {
        visibility: visible;
        opacity: 1;
        transform: rotate(15deg) scale(1);

        transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
      }

      img {
        width: 25px;
        height: auto;
        position: absolute;
        left: 95%;
        top: -50%;
      }
    }
  }
}
