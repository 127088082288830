// text align
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}


// flex
.d-flex {
    display: flex !important;
}

.flex-row {
    flex-flow: row nowrap;
}

.flex-column {
    flex-flow: column nowrap;
}

.align-item-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}


// spacing
.mt-1 {
    margin-top: 8px;
}

.mt-2 {
    margin-top: 16px;
}

.mt-3 {
    margin-top: 24px;
}

.mb-1 {
    margin-bottom: 8px;
}

.mb-2 {
    margin-bottom: 16px;
}

.mb-3 {
    margin-bottom: 24px;
}
