.phone {
  position: relative;
  display: block;

  width: calc(100vw - 80px);
  max-width: 390px;
  height: 90vh;
  max-height: 754px;
  min-height: 622px;

  margin: 5vh auto;
  border: 3px solid $white;
  border-radius: 30px;

  @media (max-width: $sm) {
    width: 90vw;
    height: 84vh;
    max-height: initial;
    min-height: initial;

    margin: 3vh auto 0;
    border: none;
  }

  &__top {
    position: absolute;
    top: 44px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 5px;
    background-color: $white;
    border-radius: 5px;

    @media (max-width: $sm) {
      display: none;
    }

    &:before {
      content: '';
      position: relative;
      display: block;
      top: -2px;
      left: -30px;
      width: 10px;
      height: 10px;
      background-color: $white;
      border-radius: 5px;
    }
  }

  &__screen {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 344px;
    height: 68vh;
    max-height: 600px;
    min-height: 455px;
    border: 3px solid $light-blue;
    border-radius: 5px;
    overflow: hidden;

    @media (max-width: $sm) {
      // top: 47%;

      width: 100%;
      max-height: initial;
      height: 100%;
      min-height: initial;
    }

    &--start {
      justify-content: flex-start;
    }
  }

  .home-button {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 42px;
    height: 42px;
    border: 3px solid $white;
    border-radius: 100%;
    box-shadow: inset 0 0 0 0 $light-blue;
    transition: box-shadow 0.4s ease-out;

    @media (max-width: $sm) {
      // bottom: 10px;
      display: none;
    }

    &:hover {
      box-shadow: inset 0 1px 7px 0 $light-blue;
    }
  }

  .overlay {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 354px;
    height: 610px;
    background-color: $white;

    @media (max-width: $sm) {
      width: 100%;
      height: 100%;
    }
  }
}