@font-face {
  font-display: swap;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: url("ibm-plex-mono-v19-latin-300.1c9d4ae5.woff2") format("woff2"), url("ibm-plex-mono-v19-latin-300.3bddfbe6.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: url("ibm-plex-mono-v19-latin-300italic.1246c484.woff2") format("woff2"), url("ibm-plex-mono-v19-latin-300italic.4f2f82b2.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: url("ibm-plex-mono-v19-latin-regular.05fafce6.woff2") format("woff2"), url("ibm-plex-mono-v19-latin-regular.cc1f1ff0.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: url("ibm-plex-mono-v19-latin-italic.037003f8.woff2") format("woff2"), url("ibm-plex-mono-v19-latin-italic.eeb8aba1.ttf") format("truetype");
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

body {
  background-color: #009bff4d;
  background-image: url("starry_sky.d96095b1.jpg");
  margin: 0;
  padding: 0;
}

body * {
  box-sizing: border-box;
}

#app {
  color: #000b4c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: IBM Plex Mono, monospace;
  font-size: 16px;
  line-height: 1.2em;
}

@media (width <= 780px) {
  #app {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #1e90ff;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
}

p, span {
  font-family: IBM Plex Mono, monospace;
}

ul {
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
  display: inline-block;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

button:hover {
  color: #fff;
}

button:focus-visible {
  outline: 1px dotted #cbcbcb;
  box-shadow: 1px 1px 4px 1px #cbcbcb4d;
}

img, figure {
  width: auto;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

p > a, span > a, a.inline-link, button.inline-link {
  color: #1e90ff;
  font-family: IBM Plex Mono, monospace;
  font-size: 16px;
  line-height: 1.2em;
  text-decoration: none;
  transition: color .3s, background-color .3s;
  position: relative;
}

@media (width <= 780px) {
  p > a, span > a, a.inline-link, button.inline-link {
    font-size: 14px;
  }
}

p > a:after, span > a:after, a.inline-link:after, button.inline-link:after {
  content: "";
  z-index: 0;
  background-color: #0000;
  transition: color .3s, background-color .3s;
  position: absolute;
  inset: 2px -3px;
}

p > a:focus-visible, span > a:focus-visible, a.inline-link:focus-visible, button.inline-link:focus-visible {
  outline: 1px dotted #cbcbcb;
  box-shadow: 1px 1px 4px 1px #cbcbcb4d;
}

p > a span, span > a span, a.inline-link span, button.inline-link span {
  z-index: 1;
  position: relative;
}

p > a:hover, span > a:hover, a.inline-link:hover, button.inline-link:hover {
  color: #fff;
}

p > a:hover:after, span > a:hover:after, a.inline-link:hover:after, button.inline-link:hover:after {
  background-color: #1e90ff;
}

p > a--light, span > a--light, a.inline-link--light, button.inline-link--light {
  color: #f0f8ff;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  word-wrap: normal !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-flow: row;
}

.flex-column {
  flex-flow: column;
}

.align-item-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.phone {
  border: 3px solid #fff;
  border-radius: 30px;
  width: calc(100vw - 80px);
  max-width: 390px;
  height: 90vh;
  min-height: 622px;
  max-height: 754px;
  margin: 5vh auto;
  display: block;
  position: relative;
}

@media (width <= 500px) {
  .phone {
    max-height: initial;
    min-height: initial;
    border: none;
    width: 90vw;
    height: 84vh;
    margin: 3vh auto 0;
  }
}

.phone__top {
  background-color: #fff;
  border-radius: 5px;
  width: 60px;
  height: 5px;
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 500px) {
  .phone__top {
    display: none;
  }
}

.phone__top:before {
  content: "";
  background-color: #fff;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  display: block;
  position: relative;
  top: -2px;
  left: -30px;
}

.phone__screen {
  border: 3px solid #f0f8ff;
  border-radius: 5px;
  flex-flow: column;
  justify-content: flex-end;
  width: 344px;
  height: 68vh;
  min-height: 455px;
  max-height: 600px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@media (width <= 500px) {
  .phone__screen {
    max-height: initial;
    min-height: initial;
    width: 100%;
    height: 100%;
  }
}

.phone__screen--start {
  justify-content: flex-start;
}

.phone .home-button {
  border: 3px solid #fff;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  transition: box-shadow .4s ease-out;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset 0 0 #f0f8ff;
}

@media (width <= 500px) {
  .phone .home-button {
    display: none;
  }
}

.phone .home-button:hover {
  box-shadow: inset 0 1px 7px #f0f8ff;
}

.phone .overlay {
  background-color: #fff;
  width: 354px;
  height: 610px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 500px) {
  .phone .overlay {
    width: 100%;
    height: 100%;
  }
}

.menu .apps {
  background-color: #f0f8ff;
  flex-flow: row;
  justify-content: space-evenly;
  padding: 10px 24px 4px;
  display: flex;
  position: relative;
}

@media (width <= 500px) {
  .menu .apps {
    padding: 10px 0 4px;
  }
}

.menu .apps__btn {
  text-align: center;
  flex-flow: wrap;
  flex: 0 0 24%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.menu .apps__btn:hover .icon {
  background-color: #007cf4;
  border: 2px solid #007cf4;
}

.menu .apps__btn:focus-visible {
  box-shadow: none;
  outline: none;
}

.menu .apps__btn:focus-visible .icon {
  outline-offset: 2px;
  background-color: #007cf4;
  border: 2px solid #007cf4;
  outline: 1px dotted #cbcbcb;
  box-shadow: 1px 1px 4px 1px #cbcbcb4d;
}

.menu .apps__btn.active .icon__inner {
  animation: 5s cubic-bezier(.42, 0, .31, .99) infinite float;
}

.menu .apps__btn .icon {
  color: #fff;
  box-sizing: border-box;
  background-color: #1e90ff;
  border: 2px solid #1e90ff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0;
  font-size: 16px;
  transition: background .3s, color .3s;
  display: flex;
  box-shadow: 0 0 9px -2px #22101073;
}

@media (width <= 780px) {
  .menu .apps__btn .icon {
    font-size: 14px;
  }
}

.menu .apps__btn .icon__inner {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.menu .apps__btn .icon__label {
  color: #1e90ff;
  text-shadow: 1px 1px 20px #c8c8ffd9;
  width: 100%;
  padding-top: 2px;
  font-family: IBM Plex Mono, monospace;
}

.work__item-wrapper {
  visibility: hidden;
  pointer-events: none;
  background: none;
  border: none;
  width: 100%;
  height: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.work__item-wrapper.show {
  visibility: visible;
  pointer-events: all;
  height: 100%;
}

.work__item-wrapper.show .work__item {
  opacity: 1;
  pointer-events: all;
  transition: opacity .3s ease-out;
  overflow-y: scroll;
}

.work__item-wrapper.show .work__item-overlay {
  opacity: .5;
  transition: opacity .15s ease-out;
}

.work__item {
  color: #fff;
  backdrop-filter: blur(5px);
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  background: #000b4c33;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 90%;
  height: 87%;
  padding: 32px 24px;
  font-family: IBM Plex Mono, monospace;
  transition: opacity .3s ease-in;
  display: block;
  position: relative;
  top: calc(5% + 34px);
  left: 5%;
  overflow: hidden;
}

@media (width <= 500px) {
  .work__item {
    height: 85%;
    padding: 32px 24px;
    top: calc(3% + 32px);
  }
}

.work__item-overlay {
  opacity: 0;
  pointer-events: all;
  background: #000b4c;
  width: 100vw;
  height: 100vh;
  transition: opacity .15s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.work__item-nav {
  backdrop-filter: blur(1px);
  z-index: 4;
  pointer-events: all;
  background: #f0f8ff1a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  height: 34px;
  display: flex;
  position: absolute;
  top: 5%;
  left: 5%;
}

@media (width <= 500px) {
  .work__item-nav {
    backdrop-filter: blur(2px);
    background: #f0f8ff40;
    height: 32px;
    top: 3%;
  }
}

.work__item-nav .close {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  display: block;
  position: relative;
}

.work__item-nav .close:focus-visible {
  outline: 1px dotted #cbcbcb;
  box-shadow: 1px 1px 4px 1px #cbcbcb4d;
}

.work__item-nav .close:hover .close__line {
  background: #cbcbcb;
}

.work__item-nav .close__line {
  background: #9cc6ed;
  border-radius: 8px;
  width: 90%;
  height: 4px;
  transition: background .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.work__item-nav .close__line:nth-of-type(2) {
  transform: translate(-50%, -50%)rotate(-45deg);
}

@media (width <= 500px) {
  .work__item-nav .close__line {
    background: #b8d1fb;
  }
}

.work__item__title {
  margin-bottom: 16px;
}

.work__item__title h2 {
  color: #f0f8ff;
  margin: 0 0 .25em;
  font-family: IBM Plex Mono, monospace;
  font-style: italic;
  display: block;
}

.work__item__media {
  flex-flow: wrap;
  place-content: flex-start;
  display: flex;
}

@media (width <= 768px) {
  .work__item__media {
    flex-flow: column;
  }
}

.work__item__media .full {
  width: 100%;
  height: auto;
  margin: 0 0 16px;
}

.work__item__media .half {
  width: calc(50% - 8px);
  margin-bottom: 16px;
  display: inline-block;
}

@media (width <= 768px) {
  .work__item__media .half {
    width: 100%;
    display: block;
  }
}

.work__item__media .half--left {
  margin-right: 8px;
}

@media (width <= 768px) {
  .work__item__media .half--left {
    margin-right: 0;
  }
}

.work__item__media .half--right {
  margin-left: 8px;
}

@media (width <= 768px) {
  .work__item__media .half--right {
    margin-left: 0;
  }
}

.work__item__media .video {
  width: 100%;
  height: 0;
  margin: 0 0 16px;
  padding-bottom: 56.25%;
  display: block;
  position: relative;
}

.work__item__media .video--miracle {
  padding-bottom: 74.25%;
}

.work__item__media .video iframe, .work__item__media .video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.work__item-wrapper .work__item--dev {
  padding: 0;
}

.work__item-wrapper .work__item--dev .video {
  max-width: calc(100% - 32px);
  margin: 0 auto;
}

.work__item-wrapper .work__item--dev .video iframe, .work__item-wrapper .work__item--dev .video video {
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  max-height: 100%;
}

.work__item-wrapper.show .work__item--dev {
  height: auto;
  max-height: 86vh;
}

.home .message {
  opacity: 0;
  width: auto;
  margin: 10px 10px 0;
  font-family: IBM Plex Mono, monospace;
  font-size: 16px;
  animation: .2s .5s forwards showText;
  display: block;
  position: absolute;
  bottom: 0;
  transform: translateY(100px);
}

@media (width <= 768px) {
  .home .message {
    font-size: 14px;
  }
}

.home .message p {
  color: #000b4c;
  background: #f0f8ff;
  border-radius: 10px;
  max-width: 72%;
  margin: 0;
  padding: 5px 10px;
  display: inline-block;
}

.home .message a {
  color: #1e90ff;
  background-color: #0000;
  text-decoration: underline;
}

.home .message a:hover {
  color: #f0f8ff;
  background-color: #1e90ff;
}

.home .message--right {
  text-align: right;
  position: absolute;
  right: 0;
}

.home .message--right p {
  color: #f0f8ff;
  background: #1e90ff;
}

.home .message--right a {
  color: #f0f8ff;
  background-color: #0000;
  text-decoration: underline;
}

.home .message--right a:hover {
  color: #1e90ff;
  background-color: #f0f8ff;
}

.home .message:first-of-type {
  animation-delay: .2s;
}

.home .message:nth-of-type(2) {
  animation-delay: 1s;
}

.home .message:nth-of-type(3) {
  animation-delay: 3s;
}

.home .message:nth-of-type(4) {
  animation-delay: 5s;
}

.home .message:nth-of-type(5) {
  animation-delay: 7s;
}

.home .message:nth-of-type(6), .home .message:nth-of-type(7), .home .message:nth-of-type(8) {
  animation-delay: 9s;
}

.home .message:last-of-type {
  margin-bottom: 10px;
}

@keyframes showText {
  from {
    opacity: 0;
    position: absolute;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    position: relative;
    transform: translateY(-10px);
  }
}

.about {
  flex-grow: 1;
  padding: 24px 16px;
  animation: .4s ease-in-out fadeIn;
  position: relative;
  overflow: scroll;
}

.about__cntnt > :first-child {
  margin-top: 0;
}

.about__cntnt h2 {
  color: #1e90ff;
  text-align: center;
  font-family: IBM Plex Mono, monospace;
  font-style: italic;
  display: inline-block;
}

@media (width <= 500px) {
  .about__cntnt h2 {
    display: block;
  }
}

.about__cntnt p {
  margin: 16px 0;
}

.about__cv {
  text-align: center;
  display: block;
}

.about__cv a.cv {
  vertical-align: text-top;
  display: inline-block;
}

.about__contact {
  flex-flow: wrap;
  align-items: flex-start;
  display: flex;
}

.about__contact__item {
  margin: 6px 0;
  position: relative;
}

.about__contact__email {
  position: relative;
}

.about__contact .copied {
  color: #fff;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  background-color: #ff69b4;
  padding: 0 .5em;
  font-size: 11px;
  transition: transform .35s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
  position: absolute;
  top: -50%;
  right: -15%;
  transform: rotate(15deg)scale(.95);
}

.about__contact .copied.show {
  visibility: visible;
  opacity: 1;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
  transform: rotate(15deg)scale(1);
}

.about__contact .copied img {
  width: 25px;
  height: auto;
  position: absolute;
  top: -50%;
  left: 95%;
}

.work {
  flex-grow: 1;
  padding: 0 0 16px;
  position: relative;
  overflow-y: scroll;
}

.work__hdr {
  text-align: center;
  color: #fff;
  text-shadow: 2px 3px 6px #024484;
  background: #f0f8ff;
  width: 100%;
  margin: 0;
  padding: 12px 0 16px;
  font-family: IBM Plex Mono, monospace;
  font-style: italic;
  display: block;
}

.work__cntnt {
  flex-flow: wrap;
  place-content: flex-start;
  margin: 0 auto;
  display: flex;
}

.work__cntnt p {
  margin: 16px 0;
}

.work__app {
  aspect-ratio: 1;
  background: #001eff;
  border-radius: 5px;
  width: calc(33.333% - 16px);
  max-height: 100%;
  margin: 8px;
  transition: box-shadow .5s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 9px -2px #0000;
}

.work__app__img {
  object-fit: cover;
  object-position: 70% 40%;
  mix-blend-mode: screen;
  pointer-events: none;
  width: 100%;
  height: 100%;
  transition: transform .3s;
  transform: scale(2);
}

.work__app:hover, .work__app:focus-visible {
  box-shadow: 0 0 9px -2px #2210104d;
}

.work__app:hover .work__app__img, .work__app:focus-visible .work__app__img {
  transform: scale(2.3);
}

.work__app-wrapper {
  margin: 8px;
}

.dev {
  flex-grow: 1;
  padding: 0 0 24px;
  position: relative;
  overflow-y: scroll;
}

.dev__hdr {
  text-align: center;
  color: #fff;
  text-shadow: 2px 3px 6px #024484;
  background: #f0f8ff;
  width: 100%;
  margin: 0;
  padding: 12px 0 16px;
  font-family: IBM Plex Mono, monospace;
  font-style: italic;
  display: block;
}

.dev__cntnt {
  margin: 0 auto;
}

.dev__cntnt > :first-child {
  margin-top: 0;
}

.dev__cntnt p {
  margin: 16px 0;
}

.dev__item {
  margin: 40px 16px;
  font-family: IBM Plex Mono, monospace;
  display: block;
}

.dev__item:last-of-type {
  margin-bottom: 0;
}

.dev__item .view {
  background: #001eff;
  border-radius: 5px;
  margin: 16px 0;
  transition: box-shadow .5s;
  overflow: hidden;
  box-shadow: 0 0 9px -2px #0000;
}

.dev__item .view:hover {
  box-shadow: 0 0 9px -2px #2210104d;
}

.dev__item .view:hover img {
  transform: scale(1.4);
}

.dev__item img {
  mix-blend-mode: screen;
  pointer-events: none;
  width: auto;
  max-width: 100%;
  height: auto;
  transition: transform .3s;
  display: block;
  transform: scale(1.3);
}

.dev__item img.half {
  width: 48%;
  display: inline-block;
}

.dev__item img.half--left {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 2%;
}

.dev__item img.half--right {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2%;
}

.dev__title h2 {
  color: #1e90ff;
  margin: 0 0 .25em;
  font-family: IBM Plex Mono, monospace;
  font-style: italic;
  display: block;
}

.dev__title a {
  color: #1e90ff;
}

.dev__title a:hover {
  text-decoration: underline;
}

.dev__tag {
  font-size: 14px;
}
/*# sourceMappingURL=index.210aeda8.css.map */
