.menu {
	.apps {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

    padding: 10px 24px 4px;

    background-color: $light-blue;

    @media (max-width: $sm) {
      padding: 10px 0 4px
    }

    &__btn {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      flex: 0 0 24%;

      text-align: center;
      text-decoration: none;

      &:hover {
        .icon {
          background-color: darken($blue, 8%);
          border: 2px solid darken($blue, 8%);
        }
      }

      &:focus-visible {
        outline: none;
        box-shadow: none;

        .icon {
          @include focus-visible();
          outline-offset: 2px;

          background-color: darken($blue, 8%);
          border: 2px solid darken($blue, 8%);
        }
      }

      &.active {
        .icon__inner {
          animation: $float;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin: 0;
        border-radius: 5px;
        border: 2px solid $blue;
        font-size: 16px;
        color: $white;
        background-color: $blue;
        box-sizing: border-box;
        transition: background 0.3s ease, color 0.3s ease;
        box-shadow: 0px 0px 9px -2px rgba(34, 16, 16, 0.45);

        @media (max-width: 780px) {
          font-size: 14px;
        }

        &__inner {
          display: block;
          width:100%;
          height:100%;
          object-fit:contain;
        }

        &__label {
          width: 100%;
          padding-top: 2px;
          color: $blue;
          font-family: $mono;
          text-shadow: 1px 1px 20px rgba(200, 200, 255, 0.85);
        }
      }
    }
	}
}
