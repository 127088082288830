.dev {
  position: relative;

  padding: 0 0 24px;

  overflow-y: scroll;

  flex-grow: 1;

  &__hdr {
    display: block;
    width: 100%;
    padding: 12px 0 16px;
    margin: 0;
    
    text-align: center;

    color: $white;
    background: $light-blue;

    font-style: italic;
    font-family: $mono;
    text-shadow: 2px 3px 6px #024484;
  }

  &__cntnt {
    margin: 0 auto;

    > *:first-child {
      margin-top: 0;
    }

    p {
      margin: 16px 0;
    }
  }

  &__item {
    display: block;
    font-family: $mono;
    margin: 40px 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .view {
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 9px -2px rgba(0,0,0,0);

      margin: 16px 0;

      background: #001eff;

      transition: box-shadow 0.5s ease;

      &:hover {
        box-shadow: 0px 0px 9px -2px rgba(34, 16, 16, 0.3);

        img {
          transform: scale(1.4);
        }
      }
    }

    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;

      transform: scale(1.3);
      mix-blend-mode: screen;

      transition: transform 0.3s ease;
      pointer-events: none;

      &.half {
        display: inline-block;
        width: 48%;

        &--left {
          margin-top: 8px;
          margin-bottom: 8px;
          margin-right: 2%;
        }

        &--right {
          margin-top: 8px;
          margin-bottom: 8px;
          margin-left: 2%;
        }
      }
    }
  }

  &__title {
    h2 {
      display: block;
        margin: 0 0 0.25em;

        color: $blue;

        font-style: italic;
        font-family: $mono;
    }

    a {
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__tag {
    font-size: 14px;
  }
}
